/* General Styles */
body {
  background-color: #ffffff;
  font-family: $font-family;
  font-size: $font-size;
  color: $font-color-primary;
  box-sizing: border-box;

  // &:before,
  // &:after {
  //   height: 5px;
  //   width: 100%;
  //   position: fixed;
  //   content: "";
  //   z-index: 999;
  // }
  // &:before {
  //   top: 0;
  // }
  // &:after {
  //   bottom: 0;
  // }

  // overflow-y: overlay;
  // scrollbar-width: none; // Firefox
  // -ms-overflow-style: none; // IE 10+

  // &::-webkit-scrollbar {
  //   // WebKit
  //   width: 10px;
  //   background-color: transparent;
  // }

  // &:hover::-webkit-scrollbar {
  //   width: 5px;
  //   height: 5px;
  // }

  // &::-webkit-scrollbar-thumb {
  //   background-color: $grey-500;
  //   border-radius: 5px;
  // }

  // @include max-screen($break-medium) {
  //   &:before,
  //   &:after {
  //     height: 2px;
  //   }
  // }
}

.font-tajawal {
  font-family: $font-family-tajawal;
}

a {
  color: $font-color-secondary;
  font-weight: $font-weight-500;
  &:hover,
  &:focus {
    text-decoration: none;
    color-scheme: none !important;
  }
}
a:active {
  color-scheme: none !important;
}
#HelpContainer {
  margin-top: 40px !important;
}
.sidebar-nav .metismenu {
  width: 100%;
}

.inlineblock {
  display: inline-block;
}
.displayblock {
  display: block;
}
.displaynone {
  display: none;
}
.overflowhidden {
  overflow: hidden;
}
.z-index {
  z-index: 9999;
}
// .p-10 {
//   padding: 10px !important;
// }

// .l-green {
//   background: linear-gradient(45deg, #9ce89d, #cdfa7e) !important;
// }
// .l-pink {
//   background: linear-gradient(45deg, #ffc0cb, #fbc1cb) !important;
// }
// .l-turquoise {
//   background: linear-gradient(45deg, #00ced1, #08e5e8) !important;
// }
// .l-cyan {
//   background: linear-gradient(45deg, #49cdd0, #00bcd4) !important;
// }
// .l-khaki {
//   background: linear-gradient(45deg, #f0e68c, #fdf181) !important;
// }
// .l-coral {
//   background: linear-gradient(45deg, #f08080, #f58787) !important;
// }
// .l-salmon {
//   background: linear-gradient(45deg, #ec74a1, #fbc7c0) !important;
// }
// .l-blue {
//   background: linear-gradient(45deg, #72c2ff, #86f0ff) !important;
// }
// .l-seagreen {
//   background: linear-gradient(45deg, #8ed8ec, #85f7b5) !important;
// }
// .l-amber {
//   background: linear-gradient(45deg, #fda582, #f7cf68) !important;
// }
// .l-blush {
//   background: linear-gradient(45deg, #dd5e89, #f7bb97) !important;
// }
// .l-parpl {
//   background: linear-gradient(45deg, #a890d3, #edbae7) !important;
// }
// .l-slategray {
//   background: linear-gradient(45deg, #708090, #7c8ea0) !important;
// }

// .xl-pink {
//   background: #f9edee !important;
// }
// .xl-turquoise {
//   background: #bdf3f5 !important;
// }
// .xl-khaki {
//   background: #f9f1d8 !important;
// }
// .xl-parpl {
//   background: #efebf4 !important;
// }
// .xl-salmon {
//   background: #ffd4c3 !important;
// }
// .xl-blue {
//   background: #e0eff5 !important;
// }
// .xl-seagreen {
//   background: #ebf2e8 !important;
// }
// .xl-slategray {
//   background: #eceeef !important;
// }

.page-top-head {
  margin: 0px;
  padding: 0px;
  font-size: 32px;
  font-weight: $font-weight-700;
}

.page-head {
  margin: 0px;
  padding: 0px;
  font-size: 24px;
  font-weight: $font-weight-700;
  text-align: start;
}

// footer {
//   padding: 0 30px;
//   padding-bottom: 15px;
//   position: absolute;
//   right: 0;

//   @include max-screen($break-small) {
//     position: relative;
//     text-align: center;
//   }
// }

// .copyright {
//   margin-bottom: 0;
//   font-size: 13px;
// }

// .btn-default {
//   color: $grey-600;
//   background-color: $col-white;
//   border-color: $grey-300;
//   padding: 6px 18px;
// }

// .btn-toolbar > .btn-group {
//   @extend .m-l-5;
// }

// .Droppable {
//   > ul {
//     padding-left: 0;
//     .dd-item {
//       .dd-content {
//         .action {
//           button:not(:last-child) {
//             margin-right: 5px;
//           }
//         }
//       }
//     }
//   }
// }

@media only screen and (max-width: 767px) {
  .hidden-xs {
    display: none;
  }
}

@media only screen and (max-width: $break-medium) {
  .hidden-sm {
    display: none;
  }
}

@media only screen and (max-width: $break-1024) {
  .hidden-md {
    display: none;
  }
}

// .echart-doughnut {
//   width: 150px;
//   height: 130px !important;
//   margin: 0 auto;
// }

.modal.fade.show {
  display: block;
  padding-right: 17px;
}

// .cke_1
//   .cke
//   .cke_reset
//   .cke_chrome
//   .cke_editor_editor1
//   .cke_ltr
//   .cke_browser_webkit {
//   border: 1px solid #f4f7f6;
// }
// .cke .cke_inner .cke_top {
//   border-bottom-width: 0;
//   .cke_toolbox {
//     padding: 0 0 5px 5px;
//     margin: 0;
//     .cke_toolbar {
//       margin-top: 5px;
//       margin-right: 5px;
//       margin-left: 0;
//       min-width: 0;
//       color: $grey-300;
//       background-color: $col-white;
//       border-color: $grey-300;
//       padding: 4px;
//     }
//   }
// }

// .cke .cke_inner .cke_bottom {
//   border-top: 0px solid #d1d1d1 !important;
//   background-color: #f5f5f5;
//   border-bottom-right-radius: 4px;
//   border-bottom-left-radius: 4px;
//   height: 2px;
//   padding-top: 1px;
//   cursor: ns-resize;
//   .cke_resizer .cke_voice_label {
//     width: 20px;
//     margin: 1px auto;
//     border-top: 1px solid #a9a9a9;
//   }
// }

// .cke .cke_inner.cke_contents {
//   width: 100%;
//   padding: 10px;
//   margin-bottom: 0;
//   font-family: Menlo, Monaco, monospace, sans-serif;
//   font-size: 14px;
//   color: #ccc;
//   background-color: #222;
//   border: 0;
//   -webkit-border-radius: 0;
//   -moz-border-radius: 0;
//   border-radius: 0;
//   box-shadow: none;
//   -webkit-box-sizing: border-box;
//   -moz-box-sizing: border-box;
//   -ms-box-sizing: border-box;
//   box-sizing: border-box;
//   resize: none;
// }

// .profile-header {
//   text-align: center;
// }

// .leaflet-container {
//   height: 600px;
//   width: 100%;
//   z-index: 1;
// }

// .hide-border {
//   &:before {
//     display: none !important;
//   }
// }
// .dd-empty {
//   border: 1px dashed #bbb;
//   min-height: 100px;
//   background-color: #e5e5e5;
//   background-image: -webkit-linear-gradient(
//       45deg,
//       #fff 25%,
//       transparent 25%,
//       transparent 75%,
//       #fff 75%,
//       #fff
//     ),
//     -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff
//           75%, #fff);
//   background-image: -moz-linear-gradient(
//       45deg,
//       #fff 25%,
//       transparent 25%,
//       transparent 75%,
//       #fff 75%,
//       #fff
//     ),
//     -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff
//           75%, #fff);
//   background-image: linear-gradient(
//       45deg,
//       #fff 25%,
//       transparent 25%,
//       transparent 75%,
//       #fff 75%,
//       #fff
//     ),
//     linear-gradient(
//       45deg,
//       #fff 25%,
//       transparent 25%,
//       transparent 75%,
//       #fff 75%,
//       #fff
//     );
//   -ms-background-size: 60px 60px;
//   background-size: 60px 60px;
//   background-position: 0 0, 30px 30px;
// }
.drawer-header {
  font-size: 20px;
  font-weight: 700;
  color: $font-color-primary;
  margin-bottom: 10px;
  text-align: start;
}
.drawer-sub-header {
  font-size: 18px;
  font-weight: 700;
  color: $font-color-primary;
  text-align: start;
}

.text-bold {
  font-weight: 700;
}

.required {
  &:after {
    content: "*";
    color: red;
    padding-left: 2px;
  }
}

/* for pagination */
// .pagination .page-item.active .page-link {
//   background-color: #6418c3;
//   border-color: #6418c3;
//   color: #fff;
// }
.css-1ba2zw7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #6418c3 !important;
  color: #fff;
}
.css-19d20mt.Mui-selected {
  background-color: #6418c3 !important;
  color: #fff;
}

div:has(> div.option-seperator) {
  border-top: 1px solid $border-color;
}

.select-school div input::placeholder {
  color: $font-color-primary;
  font-size: 14px;
}

.select-bulk div input::placeholder {
  color: $font-color-primary;
  font-size: 14px;
}

.select-bulk div input {
  line-height: inherit;
  border: none;
  outline: none;
  margin-left: 5px;
  background: transparent;
  padding: 0;
  font-size: smaller;
}

.select-school::before {
  content: url("../../../Icon/building-school.svg");
  margin: 0px 10px 0px 10px !important;
}

// .select-school .css-exshe9-InputComponent {
//   width: 100% !important;
// }
// custom -range-picker

.rs-picker-toggle:hover {
  border: 1px solid $color-primary !important;
  background-color: #fff !important;
}
.rs-picker-toggle-active {
  border: 1px solid $color-primary !important;
  box-shadow: none !important;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  border: 1px solid $color-primary !important;
  box-shadow: none !important;
}
.rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
  border: 1px solid $color-primary !important;
  box-shadow: none !important;
  background-color: $color-primary !important;
  color: #fff !important;
}
// selected date
.rs-calendar-table-cell.rs-calendar-table-cell-selected
  .rs-calendar-table-cell-content {
  background-color: $color-primary !important;
  color: #fff !important;
}
// range color change
// .rs-calendar-table-cell-in-range {
//   background-color:  $color-primary !important;
// }

// text-box color
.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: black !important;
}

.rs-calendar-table-cell:hover {
  background-color: $color-primary !important;
}
.rs-btn {
  color: $color-primary !important;
}
.rs-btn-primary {
  color: #fff !important;
  background-color: $color-primary !important;
}

// bill dashboard invoice card
.card-detail {
  height: 50px;
  border-radius: 5px;
  border: 1px solid #e5e7eb;
  margin-top: 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.invoice-card-circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.invoice-card-text {
  font-size: 16px;
  font-weight: 400;
}

.invoice-content {
  height: 50px;
  margin-top: 2%;
}
.dot-loader {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  content: "";
}
.event-dot-loader {
  width: 100%;
  height: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  content: "";
}
.media-container {
  height: 263px;
  background-color: #f3f6fb;
  border-radius: 8px;
}
.media-img-container {
  text-align: center;
  padding: 20px 20px 5px 20px;
  min-height: 230px;
}
.media-img-container img {
  max-height: 208px;
  border-radius: 8px;
}
.media-file-container {
  margin-right: 20px;
  margin-left: 20px;
}
.auto-height {
  height: auto !important;
}
.no-permission {
  display: none;
}
// for staff role button
.requested:hover .table__button-group {
  visibility: visible;
}

.table__button-group {
  visibility: hidden;
}
.fa-circle:before {
  content: "\f111";
  margin-right: 5px !important;
}
/* Notification alert  */
.icon {
  position: relative;
}

.icon::after {
  content: "";
  position: absolute;
  width: "10px";
  height: "10px";
  background-color: #00b383;
}
.toggle-sidebar {
  position: relative;
  display: inline-block !important;
}
.header-border {
  border-bottom: 1px solid #e9eaee;
  width: 100%;
  height: 72px;
}
.cust-account{
  background-color: #E8A23B;
  color: white;
  height: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: black;
  width: 220px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
/* search modal */
#modal-bx {
  position: absolute;
  min-width: 75%;
  right: 184px;
  top: 35px;
  border: none !important;
}

#modal-bx .modal.show .modal-dialog {
  transform: none;
  position: absolute;
  width: 100%;
  /* right: 278px;
  top: 30px; */
}
.search-active {
  width: 30px;
  height: 30px;
  background-color: #6418c3;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease;
  position: relative;
  top: 7px;
  left: 4px;
}
#modal-bx .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  border-radius: 0.3rem;
  outline: 0;
  border-radius: 8px !important;
}
/* for edit photo modal */
.custom-photo-modal .modal-content {
  width: 984px !important;
  min-width: 984px !important;
}

/* for attendance modal size */
.custom-attendance-modal .modal-content {
  width: 484px !important;
  min-width: 484px !important;
}
/* school-details */
.school-details {
  width: 100% !important;
  border: 1px solid #e5e7eb;
  border-radius: 12px;
}
.user-account .dropdown .dropdown-menu a:hover {
  background-color: transparent !important;
}
/* logout */
#logout {
  position: absolute;
  bottom: 20px;
}
/* for amount and balance */
.positive {
  color: $color-success;
}

.negative {
  color: $color-error;
}

.Zero {
  color: $col-dark;
}
.table-div table {
  border-radius: 12px;
  border: 1px solid $border-color !important;
  /* hide standard table (collapsed) border */
  border-style: hidden !important;
  box-shadow: 0px 0 0 0px $border-color;
}
a:disabled:hover,
a:disabled:focus {
  color: initial !important; /* or any other desired color */
  text-decoration: none;
}
/* for modal size */
.custom-modal .modal-content {
  width: 396px;
  min-width: 396px;
  /* height: 360px; */
  min-height: 350px !important;
  border: 0px !important;
  border-radius: 8px;
  padding: 24px;
}

/* change color of dateRangePicker */
.rs-picker-toggle .rs-picker-toggle-placeholder {
  color: black !important;
}

.rs-stack-item svg {
  color: black !important;
}

.rs-picker-toggle-caret svg {
  content: url("../../../Icon/calendar.svg");
  width: 16px;
  height: 16px;
}
.text-start {
  text-align: start !important;
}
.text-end {
  text-align: end !important;
}
.top-header {
  margin-right: 30px !important;
  margin-left: 13px !important;
}
[dir="rtl"] .EmojiPickerReact {
  left: 383px;
}
.dropdown-item:hover {
  background-color: transparent;
  color: $font-color;
}
.pdf-preview {
  border: 1px solid lightgray;
  width: fit-content;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}
