/* Helpers */

.bg-success {
	background-color: $color-success;
}

.bg-warning {
	background-color: $color-warning;
}

.bg-danger {
	background-color: $color-danger;
}

.bg-info {
	background-color: $color-info;
}
.bg-disable {
	background-color: $font-color-secondary;
}
//Margin
@for $i from -25 through 25 {
	.m-l-#{$i * 5} {
	    margin-left: #{$i * 5}px;
	}
  
	.m-t-#{$i * 5} {
	    margin-top: #{$i * 5}px;
	}
  
	.m-r-#{$i * 5} {
	    margin-right: #{$i * 5}px;
	}
  
	.m-b-#{$i * 5} {
	    margin-bottom: #{$i * 5}px;
	}
  }
  
  .margin-0 {
	margin: 0 !important;
  }
  
  @for $i from 0 through 25 {
	.p-l-#{$i * 5} {
	    padding-left: #{$i * 5}px;
	}
  
	.p-t-#{$i * 5} {
	    padding-top: #{$i * 5}px;
	}
  
	.p-r-#{$i * 5} {
	    padding-right: #{$i * 5}px;
	}
  
	.p-b-#{$i * 5} {
	    padding-bottom: #{$i * 5}px;
	}
  }
  
  .padding-0 {
	padding: 0 !important;
  }
  
  @for $i from 5 through 49 {
	.font-#{$i + 1} {
	    font-size: #{$i + 1}px;
	}
  }
  
  .align-left {
	text-align: left;
  }
  
  .align-center {
	text-align: center;
  }
  
  .align-right {
	text-align: right;
  }
  
  .align-justify {
	text-align: justify;
  }

.padding-25 {
	padding: 25px !important;
}

