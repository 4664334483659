*[dir="rtl"] .metismenu .arrow {
  float: left;
}
*[dir="rtl"] .metismenu .plus-times {
  float: left;
}
*[dir="rtl"] .metismenu .plus-minus {
  float: left;
}

.metismenu {
  .arrow {
    float: right;
    line-height: 1.42857;
  }
}

.metismenu .glyphicon.arrow:before {
  content: "\e079";
}
.metismenu .active > a > .glyphicon.arrow:before {
  content: "\e114";
}
.metismenu .fa.arrow:before {
  content: "\f104";
}
.metismenu .active > a > .fa.arrow:before {
  content: "\f107";
}

.metismenu .ion.arrow:before {
  content: "\f3d2";
}
.metismenu .active > a > .ion.arrow:before {
  content: "\f3d0";
}
.metismenu .plus-times {
  float: right;
}

.metismenu .fa.plus-times:before {
  content: "\f067";
}
.metismenu .active > a > .fa.plus-times {
  transform: rotate(45deg);
}
.metismenu .plus-minus {
  float: right;
}

.metismenu .fa.plus-minus:before {
  content: "\f067";
}
.metismenu .active > a > .fa.plus-minus:before {
  content: "\f068";
}

.metismenu {
  .collapse {
    display: none;
    &.in {
      // display: block;
    }
  }
  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition-timing-function: ease;
    transition-duration: 0.35s;
    transition-property: height, visibility;
  }
  // .has-arrow {
  //     position: relative;

  //     &:after {
  //         position: absolute;
  //         content: '';
  //         right: 10px !important;
  //         top: 50%;

  //         border-left: 5px solid transparent;
  //         border-right: 5px solid transparent;
  //         border-top: 5px solid $font-color-secondary;

  //         transform: rotate(45deg);
  //         transform-origin: center;

  //         background-color: transparent;
  //         transition: all .3s ease-out;
  //     }
  // }

  // .has-arrow {
  //     position: relative;
  // }

  // .has-arrow::after {
  //     content: "";
  //     position: absolute;
  //     top: 50%;
  //     right: 10px;
  //     transform: translateY(-50%);
  //     width: 0;
  //     height: 0;
  //     border-style: solid;
  //     border-width: 5px 0 5px 8px;
  //     border-color: transparent transparent transparent #000;
  // }

  .has-arrow {
    position: relative;
    display: block;
    height: 50px; /*Height should be double border thickness*/
  }

  .has-arrow::after {
    content: " ";
    display: inline-block;
    border-bottom: 2px solid #4b5563;
    border-right: 2px solid #4b5563;
    height: 6px;
    position: absolute;
    right: 25px;
    width: 6px;
    top: 16px;
    transform: rotate(45deg);
  }
  

  li.active > .has-arrow::after{
    content: " ";
    display: inline-block;
    border-bottom: 2px solid #6418C3;
    border-right: 2px solid #6418C3;
    height: 6px;

  }
  .nav-tabs-colored > li.active > .has-arrow{
    color: #9747FF !important;
  }
  // .has-arrow::before,
  // .has-arrow::after {
  //     position:absolute;
  //     display:block;
  //     content:"";

  // //     top: 50%;
  //     right: 10px;
  //     border:5px solid transparent; /*Adjust chevron size*/
  // }
  // /*Change the four instances of 'top' below to rotate chevron*/
  // /*Use (top/right/bottom/left) where you want the back of the chevron to be*/
  // .has-arrow::before {
  //     top:0;
  //     border-top-color:#b00; /*Chevron Color*/
  // }
  // .has-arrow::after {
  //     top:-10px; /*Adjust thickness*/
  //     border-top-color:#fff; /*Match chevron background colour*/
  // }
}

// *[dir="rtl"] .metismenu .has-arrow::after {
//     right: auto;
//     left: 1em;
//     transform: rotate(226deg) translate(0, -50%);
// }

// .metismenu .active > .has-arrow::after,
// .metismenu .has-arrow[aria-expanded="true"]::after {
//     transform: rotate(-180deg) translate(0, -50%);
// }

// *[dir="rtl"] .metismenu .active > .has-arrow::after,
// *[dir="rtl"] .metismenu .has-arrow[aria-expanded="true"]::after {
//     transform: rotate(225deg) translate(0, -50%);
// }
