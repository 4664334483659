/* Typography */
ul.list-justify {
  > li {
    margin-bottom: 5px;

    span,
    strong {
      float: right;
    }
  }

  &.large-number {
    > li {
      margin-bottom: 15px;
    }

    span {
      font-size: 32px;
      line-height: 1;
    }
  }
}

.text-primary {
  color: $color-primary !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.text-color-primary {
  color: $color-primary !important;
}
.font-color-primary {
  color: $font-color-primary;
}
.font-color {
  color: $font-color;
}

.text-info {
  color: $color-info;
}
.error-show {
  font-size: 12px;
  margin-top: 5px;
}
.svg-icon-error {
  width: 16px;
  height: 16px;
}
.text-success {
  color: $color-success;
}

.text-warning {
  color: $color-warning;
}

.text-danger {
  color: $color-danger;
}

.text-muted {
  color: $color-muted;
}
.text-blue {
  color: $col-blue;
}
.text-larger {
  font-size: 1.2em;
}

.text-large {
  font-size: 1.53em;
}
.basic-text {
  font-size: 14px !important;
  color: $font-color !important;
  font-weight: 400 !important;
}
.basic-bold-text {
  font-size: 14px !important;
  color: $font-color !important;
  font-weight: 600 !important;
}
.basic-large-text {
  font-size: 16px;
  line-height: 20px;
  color: $font-color-primary;
}
.extensive-large-text {
  font-size: 40px;
  font-weight: $font-weight-700;
  margin-bottom: 0 !important;
}
.extra-large-text {
  font-size: $extra-large-24;
  font-weight: $font-weight-700;
  margin-bottom: 0 !important;
}
.medium-large-text {
  font-size: $medium-large-font-18;
  font-weight: $font-weight-700;
  margin-bottom: 0 !important;
  color: $font-color;
}
.medium-lg-text {
  font-size: $medium-large-font-18;
  font-weight: $font-weight-600;
  margin-bottom: 0 !important;
  color: $font-color;
}
.large-text {
  font-size: $large-font-16;
  font-weight: $font-weight-500;
  margin-bottom: 0 !important;
}
.large-bold-text {
  font-size: $large-font-16;
  font-weight: $font-weight-600;
  margin-bottom: 0 !important;
  color: $font-color;
}
.small-text {
  font-size: $small-font-11;
  font-weight: $font-weight-400;
}
.sm-text {
  font-size: 12px;
  font-weight: $font-weight-400;
}
.extra-small-text {
  font-size: $extra-small-10;
  font-weight: $font-weight-500;
}
.comment-title-text {
  font-size: $comment-title-text;
  font-weight: $font-weight-500;
}
.text-13 {
  font-size: $font-text-13;
  color: $small-text-color;
}

.edit-btn {
  font-weight: $font-weight-600;
  color: $color-primary;
  cursor: pointer;
}
.divider-line {
  background-color: $border-color;
}
.text-bold-secondary {
  color: $font-color-secondary ;
  font-weight: 500 ;
  font-size: 14px ;
}

.text-secondary {
  color: $font-color-secondary ;
  font-weight: 400 ;
  font-size: 14px ;
}
.text-sm-secondary {
  color: $font-color-secondary ;
  font-weight: 400 ;
  font-size: 12px ;
}
.text-lg-secondary {
  color: $font-color-secondary ;
  font-weight: 400 ;
  font-size: 16px ;
}
.font-color-secondary {
  color: $font-color-secondary;
}
.notification-font-secondary {
  color: #707683;
  font-weight: 400 ;
  font-size: 12px ;
}
.link-text {
  font-size: 14px;
  font-weight: 600;
  color: $color-primary;
   cursor: pointer;
}