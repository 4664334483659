

/* .body .col-lg-6 {
    margin-bottom: 20px !important;
} */

.header h1 {
    font-size: 30px !important;
}

.get-started-btn {
    background-color: #49c5b6 !important;
}


.btn-primary {
    color: #fff;
    border-color: transparent !important;
}

.auth-wrapper {
    width: 546px !important;
}


/* login */

.login-wrap {
    position: relative;
}

.forget-btn {
    position: absolute;
    right: 0;
    padding: 5px 0;
}

.tabs-wrapper {
    width: 100%;
    background-color: #495057 !important;
    border-radius: 10px;
}


/* flag input border disable */

.PhoneInputInput {
    border: none !important;
    outline: none !important;
}

.auth-box .form-auth-small .btn,
.auth-box .form-auth-small .bottom,
.block-header,
.weather3 .top .wrapper .heading,
.weather3 .top .wrapper .location,
.stats-report,
.pricing3 .pricing-option .price {
    margin-top: 0px !important;
}


/* circle button */

.addChat {
    width: 30px;
    height: 30px;
    border-radius: 100% !important;
    margin: 0 auto !important;
    font-size: 13px !important;
}

.fa-plus {
    font-size: 13px;
}


/* chat-container */

.chat-container {
    min-height: 100% !important;
}

.chat-app .chat {
    height: 100% !important;
}

/* chat fix height */