/* Layout */

#wrapper {
  &:before,
  &:after {
    height: 100vh;
    width: 0px;
    width: 0px;
    position: fixed;
    content: "";
    z-index: 999;
    top: 0;
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }

  // @include max-screen($break-medium) {
  //   &:before,
  //   &:after {
  //     width: 2px;
  //   }
  // }
  // @include max-screen($break-medium) {
  //   &:before,
  //   &:after {
  //     width: 2px;
  //   }
  // }
}

#main-content {
  @include transition(all 0.3s ease-in-out);
  width: calc(100% - #{$sidebar-width});
  // float: left;
  position: relative;
  // margin-top: 80px;
  // padding: 0 10px;
  @include max-screen($break-large) {
    width: 100%;
    padding: 0;
  }
  .topbar {
    background-color: #ffffff;

    z-index: 22;
  }
}

.post-header {
  display: flex;
  margin-bottom: 16px;
}
.activity-container {
  max-width: 900px !important;
  max-width: 900px !important;
  margin: 0 auto;
  overflow: hidden;
  min-height: 110vh;

  @include max-screen($break-small) {
    padding: 16px;
  }
}
.card-content-details {
  .students {
    @include max-screen($break-small) {
      display: flex !important;
      flex-wrap: wrap !important;
    }
  }
}
.event-post-header {
  display: flex;
  margin-bottom: 8px;
}
.activity-description {
  margin-bottom: 8px;
  text-align: start;
}

.custom-form-wrap {
  position: relative;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25) !important;
  border-radius: 7px;
  width: 100% !important;
}
.has-arrow {
  cursor: pointer;
}
.post-details {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  justify-content: start;
  position: relative;
}
.hr-line {
  border-bottom: 1px solid #efefef;
  line-height: 25px;
}
.notification-hr-line {
  border-bottom: 2px solid $border-color;
  line-height: 25px;
}

.dots-button .badge {
  cursor: default !important;
}
.line-style-none {
  list-style: none !important;
}
.custom-input-form {
  border: none !important;
  background-color: transparent !important;
  line-height: 45px !important;
  width: 85% !important;
  position: relative;
  z-index: 0 !important;
  padding: 0 26px !important;
}

.telegram-button {
  position: absolute;
  top: 10px;
  right: 18px;
  font-size: 20px;
  cursor: pointer;
  color: $font-color-primary;
  color: $font-color-primary;
  cursor: pointer !important;
  z-index: 1 !important;
}
[dir="rtl"] .telegram-button {
  right: unset;
  left: 18px;
}

.container-80 {
  width: 80%;
  margin: 0 auto;
}
// sepration dot
.sepration-dot {
  width: 3px;
  height: 3px;
  background-color: $font-color-secondary;
  border-radius: 100%;
  display: inline-block;
  margin: 0px 8px;
}
.inside-grays {
  background-color: #f3f4f6;
  border-radius: 8px;
  min-height: 76px;
  padding: 16px;
  margin-top: 24px;
}
.inside-grays .row .col p {
  margin-bottom: 5px;
  font-weight: 500;
  color: #4b5563;
  font-size: 14px !important;
}
.container-80 {
  width: 80%;
  margin: 0 auto;
}
// sepration dot
.sepration-dot {
  width: 3px;
  height: 3px;
  background-color: $font-color-secondary;
  border-radius: 100%;
  display: inline-block;
  margin: 0px 8px;
}
.custom-plus-button {
  width: 46px !important;
  height: 46px !important;
  background-color: $color-primary;
  border-radius: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center !important;
  text-align: center;
  color: #ffffff;
  margin-right: 10px;
  cursor: pointer !important;
}
.custom-plus-button:hover {
  background-color: #742fc9 !important;
}
.custom-plus-button:hover {
  background-color: #742fc9 !important;
}

.upldod-btn input[type="file"] {
  display: none;
}
.file-label {
  display: inline-block;
  width: 52px;
  height: 52px;
  padding: 10px 20px;
  border-radius: 100%;
  background-color: $color-secondary; /* Replace with your desired background color */
  color: $font-color; /* Replace with your desired text color */
  cursor: pointer;
}

.upldod-btn input[type="file"] {
  display: none;
}
.file-label {
  display: inline-block;
  width: 52px;
  height: 52px;
  padding: 10px 20px;
  border-radius: 100%;
  background-color: $color-secondary; /* Replace with your desired background color */
  color: $font-color; /* Replace with your desired text color */
  cursor: pointer;
}

.comment-img-wrap {
  max-width: 200px !important;
  margin: 5px 10px !important;
  display: block !important;
  margin-top: 10px;
  overflow: hidden !important;
  position: relative;
  top: 10px;
  margin-bottom: 20px !important;
}

.comment-img-wrap img {
  width: 100% !important;
  height: auto !important;
  border-radius: 15px !important;
}

html.fullscreen-bg,
html.fullscreen-bg body,
html.fullscreen-bg #wrapper {
  height: 100%;
}

.vertical-align-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  display: table;
}

.vertical-align-middle {
  display: table-cell;
  vertical-align: middle;
}

.activity-card {
  border: 1px solid $border-color;
  border-radius: 8px;
  padding: 16px 16px;
  position: relative;
  .icon {
    padding-top: 10px;
  }
  .icon div {
    margin: 0px auto;
  }

  .dots-button {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .title {
    font-size: 18px;
    font-weight: 600;
  }
  .time {
    margin-top: 4px;
    font-size: 14px;
    color: $font-color-secondary;
    img {
      width: 16px;
      height: 16px;
      margin: 0px 4px;
    }
  }
  .tox-dialog{
    width: 500px !important;
  }
  .custom-select3{
    color: black !important;
    font-size: 14px !important;
  }
  .content {
    margin-top: 9px;
    white-space: "pre-wrap";
  }
  .students {
    margin-top: 10px;
    cursor: pointer;
    .badge-activity {
      border-color: $border-color;
      border-radius: 20px;
      color: $font-color-secondary;
      font-size: 14px;
      text-transform: none;
    }

    .badge-activity-count {
      border-color: $border-color;
      border-radius: 20px;
      background-color: #d8dadf;
      color: $font-color-secondary;
    }
  }
}
[dir="rtl"] .activity-card .dots-button {
  right: unset;
  left: 10px;
}

.activityicon {
  background-color: $color-light-blue !important;
}

.activityicon svg {
  color: $font-color-secondary;
  font-weight: 400;
}
.badge-students {
  border-color: $border-color;
  border-radius: 20px;
  color: $font-color-secondary;
  font-size: 14px;
  text-transform: none;
}

.left-arrow-btn,
.right-arrow-btn {
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}
.disable-right-arrow-btn {
  background-color: #e5e7eb;
  border: 1px solid #e5e7eb;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.custom-date-style {
  border: none !important;
  caret-color: transparent !important;
  font-weight: 600;
  font-size: 20px;
  color: $font-color-primary;
  cursor: pointer;
}

.date-pick-att .react-datepicker__input-container input {
  border: none !important;
  width: 120px !important;
  display: inline-block;
  font-size: 20px;
  padding: 0px !important;
  font-weight: 600;
  cursor: pointer;
}
.react-datepicker-wrapper {
  border: 0px !important;
  width: 100% !important;
  cursor: pointer;
  // border: 1px solid $border-color !important;
  color: $font-color !important;
}

.custom-input__field {
  cursor: pointer !important;
  width: 100% !important;
  height: 40px !important;
  border-radius: 8px;
  border: 1px solid $color-secondary;
  padding: 8px 12px !important;
  border-radius: 8px !important;
  font-size: 14px !important;
}

.custom-input__field::placeholder {
  color: $font-color;
}

.custom-input__field:hover {
  border: 1px solid $color-primary;
}

.custom-input__field:focus {
  border: 1px solid $color-primary !important;
  color: $font-color !important;
  outline: 0px !important;
}

// .custom-input__field:focus-visible {
//   border: 1px solid $color-primary !important;
//   // color: $font-color !important;
//   outline: 1px solid $color-primary !important;
// }

.calender-icon {
  position: absolute;
  top: 10px;

  right: 10px;
}
[dir="rtl"] .calender-icon {
  right: unset;
  left: 10px;
}

// stepper-style

.stepper-style .MuiSvgIcon-root.Mui-active {
  color: $color-primary !important;
  position: relative;
  border: 1px solid $color-primary;
  border-radius: 100%;
  padding: 2px;
  width: 26px;
  height: 26px;
  .MuiStepIcon-text {
    fill: #fff;
  }
}
.stepper-style .MuiSvgIcon-root.Mui-active::after {
  content: "";
  position: absolute !important;
  width: 32px;
  height: 32px;
  border-radius: 1px solid $color-primary;
  border-radius: 100%;
  height: 26px;
}
.stepper-style .MuiSvgIcon-root.Mui-completed {
  color: #15a84b !important;
  border: none;
}
.MuiStepIcon-root {
  color: #ffff !important;
  border: 1px solid #808080;
  border-radius: 50%;
  .MuiStepIcon-text {
    fill: #6d6d6d;
  }
}

// dotted stepper border
.stepper-style .MuiStepConnector-line {
  border-left: 1px dashed #5a6677 !important;
  display: block !important;
}
/* days circle */
.circle-container {
  display: flex;
  gap: 3px;
}
.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $color-secondary;
  display: flex;
  justify-content: center;
}
.circle-checked {
  background-color: $color-primary;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

.letter {
  font-size: 10px;
  font-weight: 400;
  color: $font-color-primary;
  display: flex;
  align-items: center;
}

.letter-checked {
  font-size: 10px;
  font-weight: 400;
  color: #fff;
  display: flex;
  align-items: center;
}
.css-1hv8oq8-MuiStepLabel-label.Mui-completed {
  color: #636979 !important;
  font-weight: 400 !important;
  font-family: "Manrope" sans-serif !important;
}

// custom circular checkbox
.circulercheckbox {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #d8dadf;
  color: $font-color-primary !important;
  position: relative !important;
  margin-right: 4px;
  cursor: pointer;
  &:focus {
    outline: 2px solid #6418c3 !important;
    border: none !important;
  }
}

// .circulercheckbox {
//   &:focus {
//     &::before {
//       outline: 2px solid #6418c3 !important;
//     }
//   }
// }
// .circulercheckbox:focus-visible {
//   outline: 0px !important;
//   border: none;
// }

.circulercheckbox.checked {
  background-color: $color-primary;
  color: #ffff;
  border: none !important;
  outline: none !important;
}

.circulercheckbox input {
  display: none;
}

.circulercheckbox-letter {
  display: block;
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: $font-color-primary !important;
  font-weight: bold;
}
.circulercheckbox-letter.checked {
  color: #ffff !important;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  font-weight: bold;
}
.circulercheckbox:checked {
  background-color: $color-primary !important;
  color: #ffff;
}
// input[type="checkbox"]:focus {
//   background-color: red !important;
//   box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
//     0 0 4px rgba(102, 175, 233, 0.6);
// }
.circulercheckbox::before {
  content: "";
  background-color: $color-primary !important;
}
[type="checkbox"].circulercheckbox:focus + .circulercheckbox:before {
  background-color: $color-primary !important;
}
// react-select placeholder color
.selected-weekDays div div {
  color: black !important;
  font-size: 14px;
}
// selected value of multi-select
.react-dropdown-multi-selected {
  background-color: white !important;
  font-weight: 400;
  padding: 2px;
}

// form-control height 40px
.form-control {
  height: 40px !important;
}

.datePicker {
  // border: 0px !important;
  cursor: pointer !important;
  width: 100% !important;
  border-radius: 8px;
  border: 1px solid $border-color;
  height: 40px;
  outline: 0px;
}

.datePicker:hover {
  border: 1px solid $color-primary;
  outline: 0px;
}

.datePicker .react-datepicker-ignore-onclickoutside {
  outline: none !important;
  border: none !important;
  padding: 0 !important;
}
.date-picker-wrap {
  cursor: pointer !important;
}

.date-picker-wrap .react-datepicker-wrapper {
  border: 0px !important;
  width: 100%;
}
// .react-datepicker-wrapper:focus {
//   border: 1px solid $font-color !important;
//   color: $font-color !important;
// }
.date-error .react-datepicker-wrapper {
  border: 1px solid $color-error !important;
}
// Custom CSS Date Picker

.react-datepicker {
  background-color: #ffffff !important;
  border: 1px solid $border-color !important;
  border-radius: 8px !important;
  overflow: hidden !important;

  font-family: $font-family !important;
  .react-datepicker__month-container {
    width: 100% !important;
  }

  .react-datepicker__header {
    background-color: #ffffff !important;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-family: $font-family !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    margin-top: 5px !important;
    margin-bottom: 16px !important;
    border: 0px !important;
  }
  .react-datepicker__month-select,
  .react-datepicker__year-select {
    width: 100%;
    height: 40px !important;
    border: 1px solid $border-color;
    border-radius: 8px;
    padding: 0px 12px !important;
    margin: 0px !important;
  }
  .react-datepicker__month-select {
    min-width: 120px !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    outline: 1p !important;
    margin-left: -5px !important;
    &:focus {
      outline: 1px solid $color-primary !important;
    }
  }
  .react-datepicker__year-select {
    min-width: 90px !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    outline: none !important;
    margin-left: 5px !important;
    &:focus {
      outline: 1px solid $color-primary !important;
    }
  }

  // for next month date hide

  .react-datepicker__week:last-child {
    display: none !important;
  }

  .react-datepicker__year-select,
  .react-datepicker__month-select {
    background-image: url("../../../Icon/chevron-down.svg");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 20px 20px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .react-datepicker__header {
    border: 0px !important;
  }
  .react-datepicker__year-dropdown-container--select,
  .react-datepicker__month-dropdown-container--select {
    margin: 0 !important;
  }
  .react-datepicker__day {
    color: $font-color !important;
    font-size: 14px !important;
  }
  .react-datepicker__day--selected {
    background-color: $color-primary !important;
    color: #ffffff !important;
    border-radius: 8px !important;
    text-align: center !important;
    align-items: center !important;
  }
  .react-datepicker__day--selected.react-datepicker__day {
    color: #ffffff !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    text-align: center !important;
    outline: none !important;
  }

  .react-datepicker__navigation--previous {
    left: 15px !important;
    top: 15px;
  }
  .react-datepicker__navigation--next {
    right: 15px !important;
    top: 15px;
  }
  .react-datepicker__navigation-icon::before {
    border-color: $font-color !important;
  }
  .react-datepicker__header__dropdown {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center;
    margin-bottom: 6px !important;
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .react-datepicker__input-container input {
    width: 100% !important;
  }

  .react-datepicker__day-name {
    color: $font-color-secondary !important;
    font-weight: 600;
    border-radius: 8px !important;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: $color-secondary !important;
    color: $font-color !important;
    // width: 32px !important;
    // height: 32px !important;
    text-align: center !important;
    border-radius: 8px !important;
  }
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-width: 2px 2px 0 0;
    content: "";
    display: block;
    height: 9px;
    position: absolute;
    top: 6px;
    width: 9px;
  }
  .react-datepicker-popper {
    z-index: 999 !important;
  }
  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    color: #5a6677 !important;
  }
  .react-datepicker__day:hover {
    background-color: $color-secondary !important;
    border-radius: 8px !important;
  }
  .react-datepicker__day--disabled.react-datepicker__day:hover {
    background-color: transparent !important;
  }
  .react-datepicker__day--selected.react-datepicker__day:hover {
    background-color: $color-primary !important;
    color: #ffffff !important;
  }
  .react-datepicker__day--outside-month {
    visibility: hidden;
  }
}

.accoridan-btn {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer !important;
  padding: 10px;
  transition: all 0.3s ease;
}
.accoridan-btn:hover {
  background-color: rgba(243, 244, 246, 1) !important;
}
.accoridan-btn:focus-visible {
  border: 0px;
  height: 24px;
  width: 24px;
}
.fa-angle-down,
.fa-angle-up {
  color: #000000 !important;
  border-radius: 4px;
  font-weight: 600 !important;
  font-size: 16px !important;
}
.accoridan-btn:active,
.accoridan-btn:active {
  background-color: rgba(243, 244, 246, 1) !important;
  text-align: center;
}

.fa-sort {
  cursor: pointer !important;
  width: 16px;
  margin-left: 8px !important;
}
.like-bx {
  cursor: pointer !important;
}

// Vertical tab setting style

.vertical-tab-setting {
  // height: calc(100% - 155px) !important;
  height: 80%;
  position: fixed;
  // top: 155px;
  margin-top: 60px;
  overflow-x: auto;

  .MuiTab-textColorPrimary {
    color: $font-color-secondary !important;
  }
  .Mui-selected {
    color: $color-primary !important;
  }
  .MuiTab-root {
    font-family: $font-family !important;
    min-height: 40px !important;
    letter-spacing: 0px !important;
    text-align: left !important;
  }
  .MuiTabs-indicator {
    position: absolute !important;
    left: 0 !important;
    min-height: 40px !important;
    padding: 0px !important;
    background-color: $color-primary !important;
  }
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    display: flex !important;
    flex-direction: row !important;
    align-items: baseline !important;
    justify-content: flex-start !important;
  }
  .css-1q2h7u5 {
    display: flex !important;
    flex-direction: row !important;
    align-items: baseline !important;
    justify-content: flex-start !important;
  }
}
.css-ydyhrd {
  padding: 0px !important;
  margin: 0px !important;
}

// Scrollbar CSS

// body {
//   overflow-y: scroll; /* Hide vertical scrollbar */
//   overflow-x: hidden; /* Hide horizontal scrollbar */
// }
html::-webkit-scrollbar {
  width: 10px !important;
  background-color: $color-secondary;
}

html::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f9fafb !important;
}

html::-webkit-scrollbar-thumb {
  width: 5px !important;
  background: $color-secondary;
  scrollbar-width: 10px;
  -ms-overflow-style: none;
  transition: all 0.4s ease;
}
html::-webkit-scrollbar-thumb:hover {
  background: #d5d6da !important;
}

// cursor type in date-picker
.react-datepicker__input-container .form-control:focus {
  cursor: default !important;
}
.react-datepicker__input-container .form-control:hover {
  cursor: pointer !important;
}

// accordian-btn bar

.vertical-bar {
  width: 1px;
  height: 20px;
  margin: 0px 12px;
  background-color: $border-color;
}
.link-button:hover .edit-svg {
  filter: invert(10%) sepia(100%) saturate(500%) hue-rotate(226deg)
    brightness(85%) contrast(126%) !important;
}
.edit-svg {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.css-exshe9-InputComponent {
  cursor: pointer !important;
}

.form-select option {
  background-color: #ffffff !important; /* Change the background color */
  color: $font-color !important; /* Change the text color */
  cursor: pointer !important;
}

.form-select::-webkit-scrollbar {
  width: 10px !important;
}

.form-select::-webkit-scrollbar-track {
  width: 4px !important;
}
.form-select::-webkit-scrollbar-thumb:hover {
  background: #d9d9d9;
  border-radius: 8px;
}
.form-select option:hover {
  cursor: pointer !important;
  -webkit-appearance: button !important;
  -moz-appearance: button !important;
  appearance: button !important;
  cursor: pointer;
}
// select focus
.form-select:focus {
  border: 0px !important;
  box-shadow: none !important;
  outline: 1px solid $color-primary !important;
}
select:hover,
option:hover {
  cursor: pointer !important;
}

// time-picker
.time-picker .timePicker {
  min-width: 100px !important;
}
.time-picker .react-datepicker-popper .react-datepicker--time-only {
  background-color: #ffffff !important;
  border: 1px solid #e5e7eb !important;
  border-radius: 8px !important;
  overflow: hidden !important;
  width: 100% !important;
}

// choose-profile
.choose-profile {
  height: 32px;
  width: 32px;
  background-color: $color-primary;
  border-radius: 100%;
  position: absolute;
  bottom: -4px;
  right: 7px;
  cursor: pointer;
  border: 2px solid #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.choose-profile:hover {
  background-color: $link-color-hover !important;
}
// notification toast
.head-toast {
  font-size: 16px;
  font-weight: 700;
  color: #ffff;
}

//custom-badge
.custom-badge {
  height: 28px;
  border-radius: 100px;
  border: 1px solid $border-color;
  padding: 4px 10px 4px 4px;
  display: flex;
  align-items: center;
  min-width: 150px !important
  ;
}
.badge__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
}

// activity vertical line
.vertical-line {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: -16px;
    top: 0;
    width: 2px;
    height: 100%;
    background-color: $border-color-head;
  }
}
[dir="rtl"] .vertical-line::before {
  right: -16px;
  left: unset;
}

.students-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  .badge-activity {
    @include max-screen($break-small) {
      margin: 4px 4px;
    }
  }
}

// more list of student
.more-student-dropdown {
  border: 1px solid $color-secondary;
  border-radius: 8px;
  background-color: $font-color-primary;
  color: #ffff;
  max-height: 400px !important;
  overflow-y: scroll;
}

.user-dropdown {
  border: 1px solid $color-secondary;
  border-radius: 8px;
  background-color: $font-color-primary;
  color: #ffff;
  padding: 8px;
}
.user-dropdown:hover {
  background-color: $font-color-primary;
}
.user-dropdown-option {
  color: $border-color;
  background-color: $font-color-primary;
  padding: 1px;
  margin: 5px 0px 5px 0px !important;
  cursor: pointer;
}
.user-dropdown-option:hover {
  color: #ffff;
}

// change color of placeholder
.datePickerContainer .react-datepicker__input-container input::placeholder {
  color: $font-color;
}
// add post table
.post-table-container {
  /* height: calc(100vh - 600px); */
  max-height: calc(100vh - 295px);
  overflow: hidden;
  overflow-y: scroll;
}
// add group table
.group-table-container {
  /* height: calc(100vh - 600px); */
  max-height: calc(100vh - 382px);
  overflow: hidden;
  overflow-y: scroll;
}

// teacher-card
.teacher-card {
  border: 1px solid $border-color;
  border-radius: 8px;
  padding: 8px 17px;
}

.home-student-card {
  border: 1px solid $border-color;
  border-radius: 8px;
  padding: 8px 17px;
  min-width: 228px;
  min-height: 108px;
}

.student-pr-card {
  border: 1px solid $border-color;
  border-radius: 12px;
  padding: 8px 17px;
  // min-height: 294px;
  // min-width: 732px;
}

.Invoice-card {
  border: 1px solid $border-color;
  border-radius: 12px;
  padding: 8px 17px;
  // min-height: 249px;
  // min-width: 354px;
}
.news-feed-card {
  border: 1px solid $border-color;
  border-radius: 12px;
  padding: 8px 17px;
  // min-height: 291px;
  // min-width: 344px;
}
.daily-activity-card {
  border: 1px solid $border-color;
  border-radius: 12px;
  padding: 16px;
  // min-height: 383px;
  // min-width: 344px;
}
.custom-circle {
  height: 8px;
  width: 8px;
  border-radius: 50%;
}
.inactive-stepper {
  width: 50%;
  background-color: #e5e6eb;
  height: 10px;
  border-radius: 10px;
}

.active-stepper {
  width: 50%;
  background-color: #6418c3;
  height: 10px;
  border-radius: 10px;
}
.custom-stepper {
  height: 10px;
  border-radius: 10px;
}
.dotted-hr {
  // height: 1px;
  // border: none;
  // background: url('../../../Icon/dotted-line.svg') repeat-x;
  // background-size: 15px 1px;

  // height: 1px;
  // background-image: linear-gradient(90deg, #000, #000 75%, transparent 75%, transparent 100%);
  // background-size: 20px 1px;
  // border: none;

  background: repeating-linear-gradient(
    to right,
    black,
    black 15px,
    transparent 15px,
    transparent 30px
  ) !important;
  height: 1px !important;
  border: none !important;

  // background-image: linear-gradient(
  //   to right,
  //   black 33%,
  //   rgba(209, 15, 15, 0) 0%
  // );
  // background-position: bottom;
  // background-size: 29px 1px;
  // background-repeat: repeat-x;
}

.calender-picker {
  background-color: #f9fafb;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapTwoLine {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal; // This allows the text to wrap if it's less than two lines
  max-height: 3.6em;
}

// PhoneInputInput
// .PhoneInput {
//   .PhoneInputCountrySelect {
//     position: absolute;
//     top: 0;
//     left: 15px !important;
//     height: 100%;
//     width: 100%;
//     z-index: 1;
//     border: 0;
//     opacity: 0;
//     cursor: pointer;
//   }
//   .PhoneInputCountry{
//     position: relative;
//     left: 20px !important;
//   }
// }
// .PhoneInput:focus {
//   border: 1px solid $font-color !important;
//   background-color: red !important;
// }

// .PhoneInputInput {
//   border: 1px solid $border-color !important;
//   border-radius: 8px !important;
//   width: 100%;
//   height: 40px;
// }
// chrome,safari,Edge,opera
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

// firefox
input[type=number]{
  -moz-appearance: textfield;
}

//Edit  modal
.custom-photo-modal .modal-content {
  width: 984px !important;
  min-width: 984px !important;

  @include max-screen($break-1024) {
    min-width: 900px !important;
    position: relative;
    // left: -100px;
  }
  @include max-screen($break-small) {
    min-width: 720px !important;
    position: relative;
    // left: -150px;
  }
}
.match-profile {
  width: 340px;
  height: 340px;
  border-radius: 100%;

  @include max-screen($break-1024) {
    width: 230px;
    height: 230px;
  }
  @include max-screen($break-small) {
    width: 200px;
    height: 200px;
  }

  .match-inner-div {
    width: 340px;
    height: 340px;

    @include max-screen($break-1024) {
      width: 230px;
      height: 230px;
    }
    @include max-screen($break-small) {
      width: 200px;
      height: 200px;
    }
  }
}
.inherit-div {
  width: 200px;
  height: 200px;

  @include max-screen($break-medium) {
    width: 130px;
    height: 130px;
  }
  @include max-screen($break-small) {
    width: 140px;
    height: 140px;
  }
}
.inherit-div-two {
  width: 140px;
  height: 140px;

  @include max-screen($break-medium) {
    width: 110px;
    height: 110px;
  }
  @include max-screen($break-small) {
    width: 100px;
    height: 100px;
  }
}
.inherit-div-third {
  width: 80px;
  height: 80px;
  @include max-screen($break-small) {
    width: 68px;
    height: 68px;
  }
}
.inherit-div-four {
  width: 40px;
  height: 40px;
  @include max-screen($break-small) {
    width: 40px;
    height: 40px;
  }
}

// small modal
.modal-content {
  @include max-screen($break-1024) {
    transform: translateX(-120px);
  }
  @include max-screen($break-small) {
    transform: translateX(-100px);
  }
}

#modal-bx {
  .modal-content {
    @include max-screen($break-1024) {
      position: relative;
      left: 120px;
    }
    @include max-screen($break-small) {
      position: relative;
      left: 100px;
    }
  }
}

.metismenu li.active .collapse {
  display: block !important;
}

// before-line
.before-line {
  width: 1px;
  height: 30px;
  background-color: #e5e7eb;
  display: inline-block;
  padding-left: 1px;
  padding-right: 1px;
  margin-right: 5px;
  margin-left: 5px;
}
.notification-circle {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: $color-primary;
}
