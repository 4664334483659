.container {
  .row {
    .loader-wrapper {
      display: flex;
      width: 600px;
      height: 600px;
      transform: translateY(30%);
      justify-content: center;
      margin: 0 auto;
    }
  }
}
.tox-dialog{
  width: 500px !important;
}

// .theme-cyan {
//   #left-sidebar {
//     z-index: 1;
//     .back-arrow {
//       margin-top: 6px;
//       img {
//         cursor: pointer;
//       }
//     }
//   }
//   .form-control {
//     .PhoneInput {
//       margin-bottom: 8px;
//     }
//   }
//   .space-before-5 {
//     margin-left: 5px;
//   }
//   .fancy-checkbox {
//     input[type="checkbox"] {
//       &:checked + span:before {
//         background: #6418c3;
//         border-color: #6418c3;
//         margin-right: 5px;
//       }
//     }
//   }
//   .logo-inside {
//     position: relative;
//     left: 5px;

//     img {
//       margin-bottom: 0.5rem;
//     }
//   }
//   .navbar-brand {
//     img {
//       margin: 10px;
//       height: 50px;
//     }
//     span {
//       font-size: 31px;
//       vertical-align: middle;
//       color: #fff;
//     }
//   }
//   .auth-main {
//     .text-separator {
//       position: relative;
//       top: 10px;
//     }
//   }
//   .cl-input-error {
//     font-size: 14px;
//     color: $color-danger;
//   }
//   .page-loader-wrapper {
//     position: fixed;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//   }
// }

.dropdown-item-activity {
  &:hover,
  &:focus {
    background: transparent;
    color: #fff !important;
  }
}
// .dropdown-item:hover,
// .dropdown-item:focus {
//   background: #fff;
//   color: #6418c3 !important;
//   cursor: pointer;
// }

.rows-selected {
  > div {
    display: inline-block;
    // padding: 0px 6px;
    background: linear-gradient(0deg, #e5e7eb, #e5e7eb),
      linear-gradient(0deg, #f3f4f6, #f3f4f6);
    border: 0;
    border-radius: 8px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #07112b;
    margin-right: 5px;
    &:nth-child(1) {
      margin-left: 0px;
    }

    .react-dropdown-select-dropdown {
      text-align: left;
      left: -9px;

      .react-dropdown-select-item:hover {
        background: rgba(100, 24, 195, 0.04);
      }
    }
  }
}

[dir="rtl"] .select-custom {
  direction: rtl !important;
}

.table-div {
  border: 1px solid #e5e7eb;
  box-shadow: 0px 2px 10px 0px #0000000a;
  position: relative;
  z-index: 0 !important;
  .table-sort-bar {
    padding: 16px;
  }

  .table-wrapper {
    // padding: 0 4px;
    overflow: auto;
    height: 60vh;
    thead {
      tr {
        th,
        td {
          // vertical-align:middle;

          &.has-select {
            padding: 0 12px;
          }
        }
      }
    }
    .fancy-checkbox {
      margin-bottom: 0;
      margin-left: 5px !important;
      input[type="checkbox"] {
        cursor: pointer !important;
        &:checked + span:before {
          font-size: 11px !important;
        }
      }
    }
  }

  .table-footer {
    .select-dropdown {
      width: auto;
      padding: 8px 0px 8px 8px;
      border-radius: 8px;
      border: 1px;
      gap: 12px;
      background: #fff;
      border: 1px solid #e5e7eb;
      cursor: pointer !important;
      min-width: 83px;
      padding: 4px;
      position: relative !important;
    }
    [dir="rtl"] .select-dropdown {
      background-position: left center !important;
    }
    .select-dropdown option {
      cursor: pointer !important;
    }
    .select-dropdown::after {
      // Style the arrow icon //
      content: "\25BC"; // Unicode downward arrow //
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      pointer-events: none;
    }
  }
}

.card {
  .select-dropdown-wrapper {
    display: flex;
    align-items: center;
    label {
      margin: 0 10px 0 0;
    }
    select {
      width: 30%;
    }
  }
}

@media screen and (max-width: 767px) {
  .card {
    .select-dropdown-wrapper {
      select {
        width: 100%;
      }
    }
  }
}

.react-dropdown-select-dropdown {
  text-align: left;
  left: -9px;

  .react-dropdown-select-item-selected {
    background: $color-primary !important;

    &:hover {
      background: $color-primary !important;
      color: #fff !important;
    }
  }
  .react-dropdown-select-item:hover {
    background: rgba(100, 24, 195, 0.04);
    color: rgb(7, 17, 43);
  }
}
.select-dropdown {
  outline: none !important;
  display: block;
  width: 100%;
  padding: 5px;
  font-weight: 400;
  color: #495057 !important;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 4px !important;
  height: 40px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 14px !important;
  cursor: pointer !important;
}
[dir="rtl"] .select-dropdown {
  background-position: left 0.75rem center !important;
}

.custom-select2 {
  position: relative;
  width: 100%;
  height: 40px;

  .select-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    font-size: 14px;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    cursor: pointer;
    outline-style: none;
    outline-width: 0px;
  }

  .select-container:hover {
    border: 1px solid #6418c3;
  }

  .selected-value {
    flex-grow: 1;
  }

  .settings-icon {
    width: 20px;
    height: 20px;
    margin-left: 8px;
  }

  .options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }

  .option {
    padding: 8px;
    cursor: pointer;
    &:hover {
      background-color: #f0f0f0;
    }
  }
}

.pdf-modal {
  width: 100%;
  height: 100%;
  margin: auto;
  align-items: center;
}

.pdf-modal .modal-body {
  height: 95vh;
  padding: 0;
}

.table-row:hover {
  background-color: rgba(100, 24, 195, 0.02);
}
