.page-calendar {
  .event-name {
    h6 {
      @extend .m-b-0;
      text-transform: capitalize;
    }
    h4 {
      font-size: 25px;
      line-height: 24px;
      @extend .m-b-0;
      @extend .m-t-0;

      span {
        @extend .displayblock;
        font-size: 11px;
        line-height: 16px;
      }
    }
    p {
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      @extend .displayblock;
      text-overflow: ellipsis;
      @extend .m-b-0;
    }
    address {
      font-size: 11px;
      @extend .m-b-0;
      @extend .m-t-5;
      i {
        font-size: 12px;
        @extend .m-r-5;
      }
    }
  }
}

.event-control {
  background-color: $grey-200;
  font-size: 13px;
  cursor: pointer;
  &:hover {
    background-color: $col-white;
  }
  a {
    color: $grey-800;
    cursor: pointer;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
  &[class*="bg-"] {
    &:not(.bg-default):not(.bg-white) {
      a {
        color: $grey-800 !important;
        opacity: 1;
        &:hover {
          color: $col-white !important;
        }
      }
    }
  }
}

.fc-overlay {
  display: none;
  position: absolute;
  min-width: 260px;
  padding: 15px 0;
  top: auto;
  left: 50%;
  margin-left: -150px;
  z-index: 1000;
  color: $grey-800 !important;
  &.left {
    left: 100%;
    top: -15px;
    padding: 0 10px;
    margin: 0;
  }
  &.right {
    left: auto;
    right: 100%;
    top: -15px;
    padding: 0 10px;
    @extend .margin-0;
  }
  .panel {
    padding: 10px;
  }
}

.fc {
  .fc-highlight {
    background-color: transparent !important;
  }
  .fc-toolbar {
    @extend .p-t-10;
    @extend .p-b-10;
    @extend .margin-0;
    color: $grey-800;
    background: transparent;
    border-radius: 0;
    display: none !important; //hide month day week & year view

    h2 {
      font-size: 16px;
      @extend .p-t-5;
      @extend .margin-0;
      text-transform: uppercase;
    }
    .fc-today-button,
    .fc-state-default {
      background: $grey-800;
      color: $grey-400;
      font-size: 14px;
      padding: 8px 18px;
      height: auto;
      border: none;
      box-shadow: none;
      text-shadow: none;
    }
  }

  .fc-view-container {
    .fc-event {
      padding: 3px 5px;
      font-size: 12px;
      border-radius: $border-radius-small;
      border: 0;
      color: $grey-100;

      &.fc-event-end:not(.fc-event-start) {
        border: 0 !important;
      }

      &:focus,
      &:hover,
      &:active {
        z-index: 1000;
        color: $col-white;
        .fc-overlay {
          @extend .displayblock;
        }
      }
    }
    .fc-view {
      &.fc-basic-view,
      &.fc-agenda {
        > table {
          > thead {
            tr {
              th,
              td {
                &.fc-widget-header {
                  border: 1px solid $grey-300;
                  padding: 10px;
                  color: $grey-700;
                }
                &.fc-agenda-gutter {
                  @extend .padding-0;
                }
              }
              td {
                &.fc-widget-header {
                  @extend .padding-0;
                }
              }
            }
          }
          tbody {
            tr {
              td {
                &.fc-widget-content {
                  border: 0;
                  border-bottom: 1px solid $grey-300;
                  border-right: 1px solid $grey-300;
                  &.fc-state-highlight {
                    background: $col-white;
                  }
                }
                &.fc-day-number {
                  padding: 5px 10px;
                  margin: 5px;
                  color: $grey-800;
                  font-size: 15px;
                  &.fc-state-highlight {
                    background-color: $col-red;
                    color: $col-white;
                    @include border-radius(50%);
                    padding: 4px 6px;
                    float: right;
                  }
                }
                &:first-child {
                  &.fc-widget-content {
                    border-left: 1px solid $grey-300;
                  }
                }
              }
            }
          }
        }
        .fc-body {
          .fc-row .fc-bg {
            border-bottom: 1px solid $grey-300;
          }
          .fc-row:last-of-type .fc-bg {
            border-bottom: 0;
          }
        }
        .fc-agenda-allday {
          .fc-agenda-axis,
          .fc-agenda-gutter {
            border-left: 1px solid $grey-300;
            border-right: 1px solid $grey-300;
            background-color: $grey-200;
            color: $grey-800;
            font-size: 12px;
            font-weight: 100;
            padding: 10px 5px;
          }
        }
        > table {
          > tbody {
            > tr {
              > td {
                &.fc-state-highlight {
                  background: $grey-200;
                }
                &.fc-sat {
                  border-right: 0;
                }
              }
            }
          }
        }
        .fc-agenda-slots {
          tr {
            line-height: 35px;
            th {
              border: 0;
              border-left: 1px solid $grey-300;
              border-right: 1px solid $grey-300;
              background-color: $grey-200;
              color: $grey-800;
              font-size: 12px;
              font-weight: 100;
              padding-top: 10px;
            }
            td.fc-widget-content {
              border-bottom: 1px solid $grey-300;
              line-height: 50px;
              &:last-child {
                border-right: 0;
              }
            }
            &.fc-minor {
              td.fc-widget-content {
                border-bottom: 1px solid $grey-300;
              }
            }
          }
        }
        .fc-agenda-divider {
          border: 1px solid $grey-300;
          .fc-agenda-divider-inner {
            background-color: $grey-200;
            height: 3px;
          }
        }
      }
    }
    .fc-list-view {
      .fc-list-item {
        color: $grey-200;
      }
    }
    .fc-other-month {
      background: $grey-100;
    }

    .fc-basic-view .fc-day-number,
    .fc-basic-view .fc-week-number {
      float: left;
      padding: 10px 0 5px 10px;
    }
    .fc-listWeek-view {
      .fc-scroller {
        .fc-list-table {
          .fc-list-heading {
            .fc-widget-header {
              span {
                padding: 10px;
                display: inline-block;
              }
            }
          }
          .fc-list-item {
            background-color: #dc3545 !important;
            color: $grey-200;
          }
          .fc-list-item td {
            padding: 10px;
            border: 0;
          }
        }
      }
    }
  }

  .fc-popover {
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
    border: none;
    padding: 10px;
    border-radius: $border-radius-large;
    .fc-event-container {
      padding: 0;
    }
    .fc-event {
      padding: 4px 8px;
      margin: 2px 0px;
    }
  }

  .fc-agendaWeek-view,
  .fc-agendaDay-view,
  .fc-listWeek-view,
  .fc-timeGridWeek-view {
    .fc-head {
      .fc-widget-header {
        span {
          padding: 10px;
          display: inline-block;
        }
      }
    }

    .fc-unthemed {
      td {
        border-color: #f4f7f6;
      }
    }
  }
}

// .fc-widget-content .fc-day-grid .fc-widget-content {
//   min-height: 3em;
// }
.fc-widget-content
  .fc-day-grid
  .fc-widget-content
  .fc-bg
  > table
  > tbody
  > tr
  td.fc-day,
.fc-widget-content
  .fc-scroller
  .fc-time-grid
  .fc-bg
  > table
  > tbody
  > tr
  td.fc-day {
  background-color: #ffffff;
}

@media only screen and (max-width: 420px) {
  .fc-toolbar .fc-right {
    display: none;
  }
  .fc-toolbar .fc-left {
    width: 100%;
    margin-bottom: 15px;
  }
  .fc .fc-toolbar .fc-today-button,
  .fc .fc-toolbar .fc-prev-button {
    float: right;
  }
}

.fc-button-primary {
  color: #fff;
  background-color: #f9fafb;
  border: none !important;
  border-color: #2c3e50;
  padding: 8px, 16px, 8px, 16px !important;
  color: #4b5563;
  height: 40px !important;
  border-radius: 8px !important;
  width: 74px !important;
}
.fc-button-primary:hover {
  background-color: none;
}

.ev-list {
  width: 100%;
  background-color: #f9fafb;
  border-radius: 8px;
  padding: 8px;
  @include max-screen($break-small) {
    margin-top: 16px !important;
  }
}
.ev-list .list-head {
  color: #07112b;
  font-family: Public Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

// .event-data {
//   position: relative;
//   background-color: #ffffff;
//   border-radius: 8px;
//   padding: 10px 15px;
//   height: 66px;
// }
// .event-data::before {
//   content: "";
//   position: absolute;
//   left: 0;
//   width: 5px;
//   height: 43px;
//   top: 10px;
//   background-color: #15a84b;
//   border-radius: 10px;
// }
.fc-toolbar .fc-header-toolbar {
  width: 100%;
  margin-bottom: 20px !important;
}

.fc-left > h2 {
  font-size: 20px !important;
  font-weight: 700 !important;
  color: $font-color;
}
.fc-left {
  // width: 100%
  position: relative;
}
.next-prev-btn {
  position: absolute;
  left: 160px;
  top: 120px;
  z-index: 2;
}
.fc-head {
  padding: 30px 0px !important;
  background-color: #f9fafb;
  height: 40px !important;
}

.dayweekmonth {
  min-height: 40px !important;

  background-color: #f9fafb;
  border-radius: 8px !important;
  padding: 2px;
}

.day-week-btn {
  color: $font-color;
  height: 40px !important;
  width: 60px !important;
  border: none !important;
  outline: none !important;
  color: $font-color-secondary !important;
  background-color: transparent;
}
.css-1s6ng7u-DropDown {
  box-shadow: none !important;
}
.day-week-btn:active {
  outline: none !important;
  border: none !important;
  // background-color: #ffffff;
}
.day-week-btn:focus {
  outline: 0px auto -webkit-focus-ring-color !important;
  outline-offset: -2px !important;
  box-shadow: none !important;
  // background-color: #ffffff !important;
}

.dropdown-bx {
  margin-left: 10px;
}
.dropdown-bx .select-custom {
  min-width: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 10px, 16px, 10px, 16px !important;
}
.dropdown-bx .select-custom .react-dropdown-select-content span {
  padding: 10px;
}
.upcoming-event-contaier .select-custom {
  min-width: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 10px, 16px, 10px, 16px !important;
}
.inside-bg {
  width: 100%;
  background-color: #ffffff !important;

  min-height: 66px;
  border-radius: 8px !important;
}
.event-data-row {
  .event-mini-text {
    color: #4b5563 !important;
  }
}
.day-week-btn:focus {
  outline: none !important;
  outline-offset: 0px;
}
.fc .fc-view-harness-active > .fc-view {
  border-style: hidden !important;
  border: 1px solid #e5e7eb !important;
  overflow: hidden;
  border-radius: 8px !important;
  width: 100% !important;
}
.fc .fc-scrollgrid-liquid {
  border-style: hidden !important;
}

//date style css
.fc .fc-daygrid-day-top {
  display: flex;
  position: absolute;
  flex-direction: row-reverse;
  justify-content: flex-start;
  right: 0;
  bottom: 0;
}
.fc .fc-daygrid-day-frame {
  height: auto !important;
  position: relative;
}

.fc .fc-row .fc-content-skeleton table,
.fc .fc-row .fc-content-skeleton td,
.fc .fc-row .fc-mirror-skeleton td {
  background: none;
  position: relative;
  border-color: transparent;
  top: 0 !important;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-content,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-list-heading td {
  border-color: #e5e7eb !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.active-btn {
  background-color: #ffffff;
  border: 1px solid #f9fafb !important;
  padding: 8px, 16px, 8px, 16px !important;
  border-radius: 8px !important;
  color: $font-color !important;
  font-weight: 400;
  outline: none !important;
}

// day view CSS
.current-day-view {
  width: 100%;
  height: 40px;
  background-color: $color-light-blue;
  border-bottom: 1px solid $color-secondary;
  border-right: 1px solid $color-secondary;
  display: flex;
  justify-content: center;
  align-items: center;
}
.current-day-view h3 {
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  padding: 0;
}
.blue-span {
  width: 20px;
  height: 20px;
  background-color: $color-primary;
  padding: 12px, 16px, 12px, 16px;
  color: $col-white;
  display: inline-block;
  border-radius: 50px;
  text-align: center;
}
.middle-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  vertical-align: baseline;
}

.timing-col {
  color: $font-color-secondary;
  font-weight: 400;
  font-size: 14px;
}

.content-area {
  position: relative;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.event-content {
  background-color: $event-bg-color;
  padding: 5px 30px;
  border-radius: 8px;
}
.left-bx-col {
  width: 10%;
}
.right-bx-col {
  border-top: 1px solid $color-secondary;
  width: 95%;
  position: relative;
  right: 15px;
  top: 10px;
}

.current-day-event-style {
  background-color: #ffcccb; /* Set the background color you want for the current day's event */
  border-radius: 4px;
  padding: 2px 4px;
  font-weight: bold;
}

.fc-daygrid-dot-event .fc-event-title {
  display: none;
}
.fc-day-today {
  background: none !important;
  position: relative;
}
.current-date {
  position: relative;
  font-size: 20px;
  font-weight: 600;
}
.fc-daygrid-day-number {
  padding: 3px;
}
.fc-day-today .fc-daygrid-day-top a {
  text-align: center !important;
  position: relative;
  left: -7px;
  bottom: 8px;
  width: 26px;
  height: 26px;
  font-weight: 400 !important;
  font-size: 14px !important;
  border-radius: 50%;
  background-color: $color-primary;
  color: #ffffff;
}
.fc-day-today .fc-daygrid-day-top a:hover {
  color: #ffffff;
}
.fc .fc-scrollgrid-section-footer > *,
.fc .fc-scrollgrid-section-header > * {
  background-color: $color-light-blue !important;
  line-height: 40px;
}
.fc .fc-daygrid-day-number {
  position: relative;
  right: 5px;
  bottom: 5px;
}
// .fc-daygrid-dot-event {
//   background-color: red;
// }
.fc-scrollgrid a:hover {
  color: none !important;
}

.fc-h-event {
  border: none !important;
  padding: 4px !important;
  width: 100px !important;
  height: 51px !important;
}
.fc-timegrid-event-harness-inset .fc-timegrid-event {
  border: none !important;
  padding: 4px !important;
  width: 100px !important;
  height: 51px !important;
}

// event shows in day view
.Day-view {
  .fc-h-event {
    width: 818px !important;
    height: 51px !important;
  }
  .fc-timegrid-event-harness-inset .fc-timegrid-event {
    width: 818px !important;
    height: 51px !important;
  }
}
// fc-event fc-event-start fc-event-end fc-event-today fc-daygrid-event fc-daygrid-block-event fc-h-event
// fc-event fc-event-start fc-event-end fc-event-today fc-daygrid-event fc-daygrid-block-event fc-h-event
// event-title & description
.event-title {
  font-size: 12px !important;
  font-weight: 600;
}

.event-description {
  font-size: 10px !important;
  font-weight: 400;
}


a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover:disabled {
  color: grey;
}
.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid $border-color !important;
  position: relative;
}

.event-dot {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px; /* Adjust spacing between dots */
}

.fc .fc-scrollgrid {
  border-collapse: collapse !important;
}

.fc-event-time {
  display: none;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  top: 2px;
  left: 6px;
  cursor: pointer;
}

.day-date {
  // width: 20px !important;
  // height: 20px !important;
  background-color: $color-primary;
  color: #ffffff !important;
  border-radius: 50%;
  padding: 5px 7px;
  margin-left: 5px;
}
#fc-dom-26 > div {
  font-size: 24px;
}
.bg-render {
  width: 26px;
  height: 26px;
  border-radius: 100%;
  background-color: $color-primary;
  text-align: center;
}

.fc-dayGridMonth-view .fc-daygrid-event,
.fc-dayGridMonth-view .fc-daygrid-event .fc-event-main-frame {
  border-radius: 100%;
  border: none;
  width: 8px !important;
  height: 8px !important;
}
.fc-dayGridMonth-view .fc-daygrid-event .fc-event-main-frame {
  content-visibility: hidden;
}

.fc-timegrid-event,
.fc-timegrid-more-link {
  border-radius: 6px !important;
  padding: 2px 4px !important;
  border: none !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  height: 30px;
}

.cal-column {
  display: flex !important;
}

.fc-dayGridMonth-view .fc-scrollgrid-sync-inner span {
  //hide Month week day
  display: none;
}
// for view event border
.event-border {
  border-top: 1px solid $border-color !important;
  margin: 0px 17px 0px 17px !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.rs-calendar-table-cell-in-range::before {
  background-color: #d2cadb !important;
}
.custom-dropdown {
  position: relative;
  display: inline-block;
}

#custom-search {
  width: 100%;
  padding: 5px;
}

.custom-options {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  display: none;
  position: absolute;
  background: #fff;
  max-height: 100px;
  overflow-y: auto;
}

.custom-options li {
  padding: 5px;
  cursor: pointer;
}

.custom-options li:hover {
  background: $grey-200;
}
.bold-text {
  font-weight: 600;
  font-size: 16px;
  color: #07112b;
}

.small-text-cal {
  font-weight: 500;
  font-size: 14px;
  color: $font-color-secondary;
}

.hidden-btn {
  border: none;
  background-color: transparent;
  margin-right: 10px;
}

.hidden-btn :hover{
  background-color:rgb(229, 231, 235);
  // width: 24px;
  // height: 24px;
  border-radius: 50%;
}
/* for calendar modal */
.calender-ev-show .modal-content {
  width: 440px !important;
}
