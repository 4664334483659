.btn {
  font-size: $font-size;
}

// new button
.custom-primary {
  background-color: $color-primary !important;
  color: $col-white !important;
  border-radius: 8px !important;
  outline: 0 !important;
  border: none !important;
  font-size: 14px;
  transition: all 0.33s ease !important;
  font-weight: 600 !important;
}
.custom-primary:focus {
  outline: 0 !important;
}

.custom-primary:active {
  background-color: $color-tapped !important;
  color: $col-white !important;
  border: none !important;
  outline: none !important;
}
.custom-primary:hover {
  background-color: $link-color-hover !important;
}
.custom-primary:disabled {
  background-color: $color-diabled !important;
  cursor: not-allowed;
}

.primary-btn {
  @extend .custom-primary;
  height: 48px !important;
  width: 92px !important;
}

.primary-lg-btn {
  @extend .custom-primary;
  height: 48px !important;
  width: 120px !important;
}

.primary-xl-btn {
  @extend .custom-primary;
  height: 48px !important;
  width: 135px !important;
}

.rounded-btn {
  border-radius: 12px !important;
}
.primary-md-btn {
  @extend .custom-primary;
  height: 48px !important;
  min-width: 78px !important;
}
.btn-border {
  border: 1px solid $border-color !important;
}
.primary-sm-btn {
  @extend .custom-primary;
  height: 40px !important;
}
.primary-post-btn {
  @extend .custom-primary;
  height: 48px !important;
  width: 40px !important
}
.primary-xs-btn {
  @extend .custom-primary;
  height: 32px !important;
  min-width: 120px !important;
}

.custom-secondary {
  background-color: #F3F4F6 !important;
  color: $font-color !important;
  border-radius: 8px !important;
  outline: 0 !important;
  border: 1px solid $color-secondary !important;
  font-size: 14px;
  font-weight: 600 !important;
  transition: all 0.33s ease !important;
}
.custom-secondary:focus {
  outline: 0 !important;
}
.rounded-btn {
  border-radius: 12px !important;
}

.custom-secondary:active {
  background-color: #F3F4F6 !important;
  color: $font-color !important;
  border: 1px solid $color-secondary  !important;
  outline: none !important;

  font-weight: 600 !important;
}
.custom-secondary:hover {
  background-color: $secondary-hover !important;
}
.custom-secondary:disabled {
  background-color: #F3F4F6 !important;
  cursor: not-allowed;
}

// Secondary Buttons
.secondary-lg-btn {
  @extend .custom-secondary;
  height: 48px !important;
  width: 89px !important;
}

.secondary-md-btn {
  @extend .custom-secondary;
  height: 44px !important;
  width: 78px !important;
}
.secondary-sm-btn {
  @extend .custom-secondary;
  height: 40px !important;
}
.secondary-xs-btn {
  @extend .custom-secondary;
  height: 32px !important;
  min-width: 78px !important;
}

// Edit Photo Modal Button
.primary-modal-btn {
  @extend .custom-primary;
  height: 48px !important;
  width: 106px !important;
}
.primary-modal-lg-btn {
  @extend .custom-primary;
  width: 127px;
  height: 48px !important;
}
.secondary-modal-btn {
  background-color: $color-secondary-alt !important;
  height: 48px !important;
  width: 127px !important;
  border: 1px solid $border-color !important;
  color: $font-color;
  font-weight: 600 !important;
  border-radius: 8px !important;
  &:hover {
    background-color: $secondary-hover !important;
  }
}
// .secondary-md-btn {
//   @extend .secondary-modal-btn;
//   width: 78px !important;
// }


.chek-in-out-btn button {
  border: 1px solid $color-secondary-alt !important;
}

.link-button {
  color: $color-primary !important;
  font-size: 14px;
  background-color: transparent;
  font-weight: 600;
}
.link-button:focus,
.link-button:active {
  transition: all 0.33s ease !important;
}

.link-button:hover {
  color: $link-color-hover !important;
}
.link-button:disabled {
  background-color: $font-color-secondary !important;
  cursor: not-allowed;
}
// .link-button.disabled {
//   color: $font-color-primary !important;
//   cursor: not-allowed;
// }

.text-field:focus {
  border-bottom: 1px solid $color-primary;
  outline: none !important;
}
.text-field::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $color-secondary;
  opacity: 1; /* Firefox */
}

.text-field:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $color-secondary;
}

.text-field::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $color-secondary;
}

// button height
.btn-large {
  height: 48px !important;
}
.btn-medium {
  height: 44px !important;
}
.btn-small {
  height: 40px !important;
}
.btn-extra-small {
  height: 32px !important;
}
/* // Modal Custom Buttom */
.confirm-btn {
  background-color: $color-primary !important;
  border-radius: 8px !important;
  font-size: "12px" !important;
  padding: 14px, 16px, 14px, 16px !important;
  color: #fff !important;
  font-weight: 600 !important;
}

.cancel-btn {
  background-color: $color-secondary-alt !important;
  border-radius: 8px !important;
  font-size: "12px" !important;
  padding: 14px, 16px, 14px, 16px !important;
  color: #07112b !important;
  font-weight: 600 !important;
  border: 1px solid $color-secondary !important;
}