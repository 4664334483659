/* primary-btn custom btn */

/* checkbox */
/* .fancy-checkbox input[type="checkbox"] + span:before {
  width: 18px !important;
  height: 18px !important;
} */

fancy-checkbox input[type="checkbox"]:checked + span:before {
  font-family: FontAwesome;
  content: "\f00c";
  font-size: 5px !important;
  color: #6418c3;
  text-align: center !important;
  line-height: 10px !important;
}

.fancy-checkbox input[type="checkbox"]:checked + span:before {
  font-size: 7px !important;
  text-align: center !important;
  line-height: 18px !important;
}

body
  > div:nth-child(11)
  > div.MuiBackdrop-root.MuiModal-backdrop.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  opacity: 0 !important;
}

body
  > div:nth-child(11)
  > div.MuiBackdrop-root.MuiModal-backdrop.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop,
.photoCollageModal .MuiBackdrop-root {
  background-color: rgb(0, 0, 0, 0.8) !important;
  opacity: 1 !important;
}
