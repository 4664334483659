.table-div {
  border-radius: $border-radius;
  border: 1px solid $border-color;
  // overflow: hidden !important;
}

.table {
  background-color: #ffffff;
  margin-bottom: 0 !important;
  //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  .thead-primary {
    th {
      color: $col-white;
      background-color: $color-primary;
      border-color: $color-primary;
    }
  }

  .thead-success {
    th {
      color: $col-white;
      background-color: $color-success;
      border-color: $color-success;
    }
  }
  .thead-danger {
    th {
      color: $col-white;
      background-color: $color-danger;
      border-color: $color-danger;
    }
  }
  .thead-info {
    th {
      color: $col-white;
      background-color: $color-info;
      border-color: $color-info;
    }
  }
  .thead-purple {
    th {
      color: $col-white;
      background-color: $purple;
      border-color: $purple;
    }
  }

  tbody {
    tr,
    th {
      td {
        vertical-align: middle;
        white-space: nowrap;
        
        text-align: start;
      }
    }
  }

  thead {
    tr {
      th {
        color: $font-color-secondary;
        font-size: 14px;
        font-weight: 400;
        border-bottom-width: 1px;
        vertical-align: text-top;

        text-align: start;
      }
    }
  }

  tbody {
    tr {
      height: 72px;
    }
  }

  tbody {
    tr {
      td {
        span {
          font-size: 14px;
          font-weight: 400;
          color: $font-color-secondary;
        }
      }
    }
  }

  tbody {
    tr {
      th {
        a {
          font-size: 14px;
          font-weight: 600;
          color: $font-color-primary;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        color: $font-color;
        font-size: 14px;

        text-align: start;
      }
    }
    a {
      color: $font-color !important;
    }

    .link-button {
      color: $color-primary !important;
    }
    .link-button:hover {
      color: $link-color-hover !important;
    }
  }

  tfoot {
    tr {
      td {
        color: #707683;
        font-size: 13px;
        text-align: center;
      }
    }
  }
}

.table th,
.table td {
  vertical-align: middle;
  border-top: 0px solid #dee2e6 !important;
}

.table-bulk-student {
  width: 130%;
}

@include max-screen($break-small) {
  body {
    overflow-x: hidden !important;
  }
}

.tablet-specific {
  @include max-screen($break-small) {
    position: relative;
    left: 25px;
  }
}

.tablet-specific-col {
  @include max-screen($break-small) {
    position: relative;
    left: -15px;
  }
}

.summary {
  .react-dropdown-select-type-single {
    -webkit-box-flex-wrap: no-wrap !important;
    -webkit-flex-wrap: no-wrap !important;
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
    padding: 0 0px 0px 4px !important;
  }
}

.home {
  .react-dropdown-select-type-single {
    flex-wrap: nowrap !important;
  }
}
