.chat-app {
  .people-list {
    width: 280px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    padding: 20px;
    z-index: 7;
  }

  .chat {
    margin-left: 280px;
    border-left: 1px solid #eaeaea;
  }
}

.people-list {
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;

  .chat-list {
    li {
      padding: 15px 20px;
      list-style: none;
      border-radius: 3px;
      border-bottom: 1px solid $border-color;

      &:hover {
        background: rgba(100, 24, 195, 0.06);
        cursor: pointer;
      }

      &.active {
        background: rgba(100, 24, 195, 0.06);
      }
    }

    img {
      // width: 45px;
      border-radius: 50%;
    }
  }

  img {
    float: left;
    border-radius: 50%;
  }

  .about {
    float: left;
    padding-left: 8px;
    width: 100%;
    color: #5a6677;
    font-weight: normal;

    .name {
      font-size: 14px;
      font-weight: 600;
      // padding: 0px 20px 0px 0px;
    }
    .last-message {
      font-size: 12px;
      display: inline-block;
    }

    .child {
      font-size: 12px;
      color: $font-color-secondary;
    }

    .last-message-time {
      float: right;
      font-size: 12px;
      color: $font-color-secondary;
    }
    .online {
      font-size: 12px;
      color: green !important;
    }
  }

  .unread {
    .name {
      color: $font-color-primary;
    }
    .last-message {
      color: $font-color-primary;
    }
  }
  .status {
    color: $font-color-secondary;
    font-size: 12px;
  }
}

.chat {
  .chat-header {
    padding: 16px 20px;
    border-bottom: 1px solid $border-color;

    .chat-about {
      float: left;
      padding-left: 10px;

      .title {
        font-size: 14px;
        color: $font-color-primary;
        font-weight: 600;
        line-height: 1;
      }

      .subtitle {
        font-size: 14px;
        color: $font-color-secondary;
      }
    }
  }

  .chat-history {
    padding: 20px;
    font-size: 14px;
    background-color: #f8f8fa;
    ul {
      padding: 0;

      li {
        list-style: none;
        margin-bottom: 15px;
        width: 100%;

        &:last-child {
          // margin-bottom: 0px;
        }

        .message {
          width: 80%;

          .message-text {
            position: relative !important;
            background-color: #ffffff;
            display: inline-block;
            padding: 12px;
            margin-left: 8px;
            margin-bottom: 20px !important;
            border-radius: 8px 8px 8px 0px !important;
            & .unread {
              font-weight: 700;
            }
            max-width: 80%;
          }
          .Time {
            position: absolute !important;
            right: 0px !important;
            bottom: -26px;
            margin-top: 16px !important;
            white-space: nowrap; 
          }
        }

        .my {
          float: right;
          .message-text {
            background-color: #e5e6eb;
            float: right;
          }
          .Time {
            justify-content: right;
            white-space: nowrap; 
          }
        }
      }
    }

    .message-data {
      margin-bottom: 15px;

      img {
        border-radius: 40px;
        width: 40px;
      }
    }

    .message-data-time {
      color: #434651;
      padding-left: 6px;
    }

    .message-area {
      color: $font-color-primary;
      padding: 1px 20px;
      line-height: 26px;
      border-radius: 7px;
      display: inline-block;
      position: relative;
    }
  }

  .chat-message {
    padding: 0px 20px;
    margin-top: 10px;
  }
  .chat-input {
    border-radius: 8px !important;
    border-color: $border-color !important;

    &.chat-input:focus{
      outline: 1px solid $color-primary;
    }
    
  }
  &.chat-input:focus{
    outline: 1px solid $color-primary;
  }
}

.online,
.offline,
.me {
  // margin-right: 2px;
  font-size: 8px;
  vertical-align: middle;
}

.online {
  color: #86c541;
}

.offline {
  color: #e47297;
}

.me {
  color: #1d8ecd;
}

.float-right {
  float: right;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

@media only screen and (max-width: 767px) {
  .chat-app {
    .people-list {
      height: 465px;
      width: 100%;
      overflow-x: auto;
      background: #fff;
      left: -400px;
      display: none;

      &.open {
        left: 0;
      }
    }

    .chat {
      margin: 0;

      .chat-header {
        border-radius: 0.55rem 0.55rem 0 0;
      }
    }

    .chat-history {
      // height: 300px;
      overflow-x: auto;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  // .chat-app {

  //     .chat-list {
  //         height: 650px;
  //         overflow-x: auto;

  //     }

  //     .chat-history {
  //         height: 600px;
  //         overflow-x: auto;
  //     }
  // }
}

/* Landscape */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .chat-app {
    .chat-list {
      height: 480px;
      overflow-x: auto;
    }

    .chat-history {
      height: calc(100vh - 350px);
      overflow-x: auto;
    }
  }
}

.chat-wrapper {
  height: calc(100vh - 225px);
  border-bottom: 1px solid #ddd;
  overflow-x: hidden;
  overflow-y: scroll;
}
.chat-wrapper1 {
  height: calc(100vh - 159px);
  border-bottom: 1px solid #ddd;
  // overflow-x: hidden;
  // overflow-y: scroll;
}
.chatBox {
  height: calc(100vh - 228px);
  overflow-x: hidden;
  overflow-y: scroll;
}

/* chat custom scrollbar */

.chat-wrapper::-webkit-scrollbar,
.chatBox::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}
.chat-wrapper::-webkit-scrollbar-thumb,
.chatBox::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 5px;
}
.chat-wrapper:hover::-webkit-scrollbar,
.chatBox:hover::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

// message-tabs-wrap

.message-tabs-wrap {
  .css-149jrqn-MuiButtonBase-root-MuiTab-root {
    font-family: $font-family !important;
    letter-spacing: 0;
    width: 33%;
  }
  .MuiButtonBase-root {
    font-family: $font-family !important;
    letter-spacing: 0;
    width: 33%;
  }
  .css-1aquho2-MuiTabs-indicator {
    background-color: $color-primary;
  }
  .css-ttwr4n {
    background-color: $color-primary;
  }
  .MuiTabs-root {
    border-bottom: 1px solid $border-color;
  }
}
.navbar-counter-badge {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-size: 12px !important;
  text-align: center;
  font-weight: 400;
  background-color: $color-primary;
  color: #ffffff;
}
.counter-badge {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d8dadf;
  border-radius: 100%;
  font-size: 12px !important;
  text-align: center;
  font-weight: 400;
  color: $font-color-secondary;
}
.css-149jrqn-MuiButtonBase-root-MuiTab-root.Mui-selected .counter-badge {
  background-color: $color-primary;
  color: #ffffff;
}
.unread-messages-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.half-hr {
  flex: 1;
  border: none;
  border-top: 1px solid #000;
}

.white-box {
  background-color: #fff;
  padding: 8px 16px 8px 16px;
  border: 1px solid $border-color;
  // width: 149px;
  height: 37px;
  border-radius: 100px;
}
// for plus icon
.chat-plus-icon {
  position: absolute !important;
  right: 12px;
  bottom: 35px;
  cursor: pointer;
}
[dir="rtl"] .chat-plus-icon {
  left: 32px;
  right: unset;
}
.plus-icon-dropdown {
  padding: 6px 6px 6px 0px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
}
// for new-staff/parent/group table
.chat-staff-container {
  height: calc(100vh - 124px);
  overflow: hidden;
  overflow-y: scroll;
}
.chat-table-container {
  height: calc((100vh - 252px)/2);
  overflow: hidden;
  overflow-y: scroll;
}
// for group label
.chat-group-label {
  height: 32px;
  border: 0px 0px 1px 0px;
  padding: 8px 20px 8px 20px;
  background-color: #f3f4f6;
  margin-top: 10px;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: $font-color-primary;
}