/* Side Bar Menu */

#left-sidebar {
  @include transition(all 0.4s ease-in-out);
  width: $sidebar-width;
  background-color: $color-light-blue;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-right: 1px solid $border-color;
  scrollbar-width: none; // Firefox
  z-index: 999;

  

  &::-webkit-scrollbar {
    // WebKit
    width: 5px;
  }
}

@media screen and (min-width: 768px) {
  #left-sidebar{
    z-index: 3;
  }
}



// @media screen and (max-width: 1200px) {
//   #left-sidebar {
//     width: 0px !important;
//   }
// }

#left-sidebar .classadia-logo {
  font-size: 28px;
  color: $font-color-primary;
  font-weight: $font-weight-700;
  position: relative;
  left: 7px;
  top: 3px;

}

.layout-fullwidth #wrapper {
  #left-sidebar {
    left: -$sidebar-width;

    &.ps {
      overflow: visible !important;
    }
  }
  #main-content {
    width: 100%;
  }
}

// .offcanvas-active {
//   #left-sidebar {
//     left: -$sidebar-width;
//   }
// }

.sidebar-nav {
  ul {
    @extend .padding-0;
    @extend .margin-0;
    list-style: none;
  }

  .metismenu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    > li {
      margin-bottom: 12px;
      -webkit-box-flex: 1;
      -ms-flex: 1 1 0%;
      flex: 1 1 0%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      position: relative;

      &:before {
        content: "";
        width: 5px;
        height: 40px;
        background-color: transparent;
        border-radius: 0px 4px 4px 0px;
        position: absolute;
        left: 0;
      }

      &.active:before {
        background-color: $color-primary;
      }
      &.active > a {
        background: rgba(100, 24, 195, 0.04);
        border-radius: 8px;
        font-weight: $font-weight-600;
        color: $color-primary !important;
      }
      a {
        @include transition(all 0.3s ease-out);
        height: 40px !important;
        margin-left: 14px !important;
        width: 220px;
        position: relative;
        @extend .displayblock;
        padding: 10px 16px;
        outline-width: 0;
        font-size: 14px;
        color: $font-color-secondary;
        i {
          @extend .m-r-10;
          font-size: 14px;
          img {
            vertical-align: top;
          }
        }

        &:focus,
        &:hover {
          text-decoration: none;
          color: $font-color-secondary;
        }
      }

      ul.collapse {
        position: relative;
        margin-left: 24px !important;
        border-left: 1px solid #e5e6eb;
        margin-top: 8px !important;

        li {
          margin-bottom: 6px;
        }

        li.active {
          a {
            color: $color-primary;
          }
        }
      }
    }
  }
}

[dir="rtl"] .sidebar-nav .metismenu > li:before {
  left: unset;
  right: 0;
}
.sidebar-nav .metismenu > li.active a .svg-icon {
  filter: invert(10%) sepia(100%) saturate(238%) hue-rotate(226deg)
    brightness(90%) contrast(280%);
}

.rotate-img {
  filter: invert(10%) sepia(100%) saturate(238%) hue-rotate(226deg)
    brightness(90%) contrast(280%);
}

.user-account {
  @extend .align-left;
  display: flex;
  align-items: center;

  .user-photo {
    width: 40px;
    height: 40px;
    @extend .m-r-10;
  }

  .user-name {
    @extend .displayblock;
    color: inherit;
    margin-top: 4px;

    img {
      position: absolute;
      right: -5px;
      top: 8px;
      width: 16px;
    }

    span {
      position: relative;
      right: 10px;
      font-weight: 600;
      font-size: 16px;
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .dropdown {
    @extend .inlineblock;
    @extend .align-left;
    @extend .m-t-5;

    .dropdown-menu {
      transform: none !important;
      border: none;
      box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.5);
      padding: 15px;
      background: #17191c;
      border-radius: $border-radius-large;

      .divider {
        border-bottom: 1px solid $grey-800;
        margin: 10px 0;
      }

      a {
        @extend .displayblock;
        padding: 10px;
        font-size: 14px;
        color: $grey-500;

        &:hover {
          color: $col-white !important;
          i {
            color: $col-white;
          }
        }

        i {
          @extend .m-r-10;
          font-size: 14px;
        }
      }
    }
  }
}

.dropdown-toggle {
  padding-right: 0;
  border: none;
}

.dropdown-toggle::after {
  display: none !important;
  // display: inline-block;
  // width: 0;
  // height: 0;
  // margin-left: 0.255em;
  // vertical-align: 0.255em;
  // border-top: 0.3em solid;
  // border-right: 0.3em solid transparent;
  // border-bottom: 0;
  // border-left: 0.3em solid transparent;
}
.divider {
  width: 1px;
  height: 30px;
  background-color: #e9eaee;
  display: inline-block;
  position: relative;
  top: 0px;
  margin: 0px 19px !important;
}
[dir="rtl"] .divider {
  margin: auto !important;
}

@media screen and (max-width: 1200px) {
  #sm-toggle-btn {
    display: block !important;
  }
  .full-screen-toggle {
    display: none !important;
  }
}
@media screen and (max-width: 1024px) {
  .collops-main {
    display: none !important;
  }
}

@media screen and (min-width: 1025px) {
  .collops-tablet {
    display: none !important;
  }
}
